import React from 'react'
import SEOTools from '../components/SEOTools'
import { Col, Container, Row } from 'react-bootstrap'
import FaqItem from '../components/FaqItem'
import { graphql } from 'gatsby'
import HeroUnit from '../components/HeroUnit'
import { faFileInvoiceDollar, faGlobe, faQuestionCircle, faUserShield } from '@fortawesome/free-solid-svg-icons'
import ContactForm from '../components/Forms/ContactForm'
import FaqCategoryScrollButton from '../components/FaqCategoryScrollButton'
import { getSrc } from 'gatsby-plugin-image'
import StyledSideCol from '../components/styled/StyledSideCol'
import useLandbot from '../hooks/useLandbot'
import Layout from '../components/Layout'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const FaqPage = ({ data }) => {
    // Grab faq items from queried data
    const faqItems = data.faqItems.nodes
    // Split items based on category
    const top = faqItems.filter(item => item.category.includes('topQuestions'))
    const general = faqItems.filter(item => item.category.includes('general'))
    const invoices = faqItems.filter(item => item.category.includes('invoices'))
    const security = faqItems.filter(item => item.category.includes('security'))
    const transactions = faqItems.filter(item => item.category.includes('transactions'))

    useLandbot('https://chats.landbot.io/v3/H-976561-UF189YQZTI9JGMEW/index.json')

    return (
        <Layout>
            <SEOTools
                title='Tuki'
                description='Tervetuloa FAQ-sivulle! Täältä löydät vastaukset yleisimpiin kysymyksiin Siru Mobileen liittyen. Jos et löydä kaipaamasi tietoa, olethan yhteydessä asiakaspalveluumme.'
                image={getSrc(data.heroBg.childImageSharp)}>
                {/* eslint-disable-next-line */}
                <html {...schemaAttributes} />
            </SEOTools>

            <HeroUnit backgroundImage={data.heroBg}>
                <Row className='d-flex align-items-center justify-content-center text-center text-white my-5'>
                    <Col md='8'>
                        <h1>Etsitkö apua? Täältä sitä löytyy.</h1>
                    </Col>
                </Row>
            </HeroUnit>

            <section className='pt-2 mb-5'>
                <Container>
                    <Row className='my-5'>
                        <StyledSideCol md='3' className='mb-3'>
                            <div className='text-secondary top'>Yhtiömme</div>
                            <div className='h3 bottom'>
                                <strong>Siru Mobile</strong>
                            </div>
                        </StyledSideCol>
                        <Col>
                            <p>
                                Tervetuloa FAQ-sivulle! Täältä löydät vastaukset yleisimpiin kysymyksiin Siru Mobileen
                                liittyen. Jos et löydä kaipaamasi tietoa, olethan yhteydessä asiakaspalveluumme.
                            </p>
                        </Col>
                    </Row>

                    <Row className='text-center mb-5'>
                        <FaqCategoryScrollButton icon={faQuestionCircle} text='Yleiset' scrollToId='general' />
                        <FaqCategoryScrollButton icon={faGlobe} text='Talletukset' scrollToId='transactions' />
                        <FaqCategoryScrollButton icon={faFileInvoiceDollar} text='Laskutus' scrollToId='invoices' />
                        <FaqCategoryScrollButton icon={faUserShield} text='Turvallisuus' scrollToId='security' />
                    </Row>

                    <h2 className='mb-5'>Suosittuja kysymyksiä</h2>

                    {top.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='general' className='my-5 pt-4'>
                        Yleiset kysymykset
                    </h2>

                    {general.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='transactions' className='my-5 pt-4'>
                        Talletukset & rajoitukset
                    </h2>

                    {transactions.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='invoices' className='my-5 pt-4'>
                        Laskutus
                    </h2>

                    {invoices.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <h2 id='security' className='my-5 pt-4'>
                        Turvallisuus
                    </h2>

                    {security.map(item => (
                        <FaqItem
                            key={item.slug.current}
                            question={item.question}
                            answer={item._rawAnswer}
                            slug={item.slug.current}
                        />
                    ))}

                    <Row className='mt-5 mb-4 pt-5'>
                        <StyledSideCol md='3' className='mb-3'>
                            <div className='text-secondary top'>Ota yhteyttä</div>
                            <div className='h3 bottom'>
                                <strong>Kysy meiltä</strong>
                            </div>
                        </StyledSideCol>
                        <Col>
                            <p>
                                Onko sinulla kysymyksiä tai palautetta? Asiakaspalvelumme palvelee sinua kaikissa
                                asioissa. Täytä oheinen lomake ja kerro miten voimme auttaa.
                            </p>
                        </Col>
                    </Row>

                    <ContactForm name='contact' />
                </Container>
            </section>
        </Layout>
    )
}

/**
 * Page query can be dynamic with variables. Can only be run on a page level.
 */
export const query = graphql`
    query ($langKey: String) {
        # Get hero image
        heroBg: file(relativePath: { eq: "supportive-woman.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
        # Get faq items
        faqItems: allSanityFaqItem(filter: { i18n_lang: { eq: $langKey } }, sort: { fields: weight, order: ASC }) {
            nodes {
                slug {
                    current
                }
                question
                category
                _rawAnswer(resolveReferences: { maxDepth: 1 })
            }
        }
    }
`

const schemaAttributes = { itemScope: true, itemType: 'https://schema.org/FAQPage' }

export default FaqPage
